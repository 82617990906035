import { gql } from "graphql-request";
import graphQLClient from "@/api/graphql-client";
import type {
  CompanyAltCase,
  OrderDetail,
  OrderLineItemAltCase,
  MessageAltCase,
  OrderTranslationJobsAltCase,
  OrderLineItem,
  QueryError,
} from "@/utils/types";
import { formatNumber } from "@/utils/formatter";

const ORDER_DETAIL_BY_ORDER_ID_QUERY = gql`
  query OrderDetailByOrderId($companyId: ID!, $orderId: ID!) {
    company(company_pid: $companyId) {
      ai_order_detail(butter_order_id: $orderId) {
        created_ts
        customer_address
        customer_id
        customer_name
        delivery_date
        buyer_note
        id
        line_items {
          description
          id
          last_ordered_date
          product_id
          quantity
          unit
          unit_price
          product_price
          slug
          similar_suggestions
        }
        messages {
          author
          author_type
          created_ts
          body
          ai_resp
          id
          index
          job_id
          participant {
            id
            type
            name
            user_id
            conversation_id
            channel_type
            phone_number
            email
            user {
              email
              name
              phone_number
              pid
              title
            }
          }
          type
          subject
          media_text_recognitions {
            id
            media_type
            media_url
            result
          }
          creation_status
          creation_error
        }
        status
        supplier_pid
        translation_jobs {
          action
          id
          result
          text
        }
        delivery_date
        updated_ts
        meta_data
        pending_data
        delivery_address {
          line1
          line2
          city
          state
          zip
          country
        }
      }
    }
  }
`;

export const queryOrderDetailByOrderId = (
  companyId: string,
  orderId: string
): {
  queryKey: [string, string, string];
  queryFn: () => Promise<{ order: OrderDetail } | QueryError>;
} => ({
  queryKey: ["orderDetail", companyId, orderId],
  queryFn: async (): Promise<{ order: OrderDetail } | QueryError> => {
    try {
      const { company }: { company: CompanyAltCase } =
        (await graphQLClient.request(ORDER_DETAIL_BY_ORDER_ID_QUERY, {
          companyId,
          orderId,
        })) ?? {};

      const {
        id,
        customer_id,
        status,
        line_items,
        messages,
        translation_jobs,
        created_ts,
        customer_name,
        delivery_date,
        meta_data,
        pending_data,
        delivery_address,
        buyer_note,
      } = company?.ai_order_detail ?? {};
      const order = {
        id,
        customerId: customer_id,
        companyId,
        status,
        lineItems:
          line_items.map((elem: OrderLineItemAltCase) => {
            let similarSuggestions = [];
            try {
              similarSuggestions = JSON.parse(elem.similar_suggestions as string);
            } catch (err) {
              console.log(err);
            }
            return {
              id: elem.id,
              productId: elem.product_id,
              slug: elem.slug,
              description: elem.description,
              lastOrderedDate: elem.last_ordered_date,
              quantity: Number(formatNumber(elem.quantity)),
              unit: elem.unit,
              unitPrice: elem.unit_price ? parseFloat(elem.unit_price) : null,
              productPrice: elem.product_price ? parseFloat(elem.product_price) : null,
              similarSuggestions,
            };
          }) ?? [],
        messages: messages.map((elem: MessageAltCase) => {
          const mediaTextRecognitions = elem.media_text_recognitions?.map((media) => {
            const mediaResult = media ? JSON.parse(media.result) : null;
            if (mediaResult) {
              let [startRange, endRange] = [0, elem.subject ? elem.subject.length : 0];
              mediaResult.segments.forEach((segment: any, index: number) => {
                endRange = endRange + (index === 0 ? segment.text.trimStart().length : segment.text.length) + 1;

                segment.textRange = [startRange, endRange];
                startRange = endRange;
              });
            }
            return {
              id: media.id,
              type: media.media_type,
              url: media.media_url,
              ...mediaResult,
            };
          });
          return {
            id: elem.id,
            author: elem.author,
            authorType: elem.author_type,
            createdTs: elem.created_ts,
            body: elem.subject ? `${elem.subject}\n${elem.body}` : elem.body,
            aiResp: elem.ai_resp,
            index: elem.index,
            jobId: elem.job_id,
            participant: {
              channelType: elem?.participant?.channel_type,
              conversationId: elem?.participant?.conversation_id,
              createdAt: elem?.participant?.created_ts,
              emailAddress: elem?.participant?.email,
              phoneNumber: elem?.participant?.phone_number,
              participantId: elem?.participant?.id,
              displayName: elem?.participant?.name,
              type: elem?.participant?.type,
              userId: elem?.participant?.user_id,
              user: {
                userId: elem?.participant?.user?.pid,
                userEmail: elem?.participant?.user?.email,
                userPhone: elem?.participant?.user?.phone_number,
                userName: elem?.participant?.user?.name,
                companies: elem?.participant?.user?.butter_companies,
                userRole: elem?.participant?.user?.role,
                userTitle: elem?.participant?.user?.title,
              },
            },
            type: elem.type,
            subject: elem.subject,
            mediaTextRecognitions,
            creationStatus: elem.creation_status,
            creationError: elem.creation_error,
          };
        }),
        translationJobs: translation_jobs.map((elem: OrderTranslationJobsAltCase) => {
          let lineItems: OrderLineItem[] = [];
          let deliveryDate = "";
          try {
            const result = JSON.parse(elem.result);

            if (result) {
              lineItems =
                result.line_items?.map((item: OrderLineItemAltCase) => {
                  let similarSuggestions: OrderLineItemAltCase[] = [];
                  if (typeof item.similar_suggestions === "string") {
                    try {
                      similarSuggestions = JSON.parse(item.similar_suggestions);
                    } catch (err) {
                      console.log(err);
                    }
                  } else {
                    similarSuggestions = item.similar_suggestions ?? [];
                  }
                  const suggestions = similarSuggestions?.map((t) => ({
                    productId: t.product_id,
                    slug: t.slug,
                    description: t.description,
                    lastOrderedDate: t.last_ordered_date,
                    quantity: t.quantity,
                    unit: t.unit,
                    unitPrice: t.unit_price ? parseFloat(t.unit_price) : t.unit_price,
                  }));

                  return {
                    productId: item.product_id,
                    slug: item.slug,
                    description: item.description,
                    lastOrderedDate: item.last_ordered_date,
                    quantity: Number(item.quantity) ?? Number(suggestions[0].quantity), // Note: need to investigate why quantity is not in the result string
                    unit: item.unit,
                    unitPrice: item.unit_price ? parseFloat(item.unit_price) : item.unit_price,
                    similarSuggestions: suggestions,
                    textRange: item.text_range,
                    isManual: item.is_manual ?? false,
                    isManualAdd: item.is_manual_add ?? false,
                  };
                }) ?? [];
              deliveryDate = result.delivery_date;
            }
          } catch (err) {
            console.log(err);
          }
          return {
            id: elem.id,
            action: elem.action,
            text: elem.text,
            result: {
              lineItems,
              deliveryDate,
            },
          };
        }),
        createdTs: created_ts,
        deliveryDate: delivery_date,
        buyerNote: buyer_note,
        customerName: customer_name,
        metaData: meta_data,
        pendingData: pending_data
          ? {
              messages:
                (pending_data?.anonymous_customer_messages ?? pending_data.messages)?.map((elem) => ({
                  type: elem.type,
                  content: elem.content,
                  attachmentMedia: elem.attachment_media
                    ? {
                        mediaUrl: elem.attachment_media.media_url,
                        mediaType: elem.attachment_media.media_type,
                      }
                    : { mediaUrl: "", mediaType: "" },
                })) ?? [],
            }
          : { messages: [] },
        deliveryAddress: delivery_address,
      };
      return { order };
    } catch (error) {
      return {
        error: {
          message: "An error occurred while querying order details: " + String(error),
        },
      };
    }
  },
});
