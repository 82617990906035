import { useState, useEffect, useRef } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Params, useLoaderData, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { BiFoodMenu } from "react-icons/bi";
import _ from "lodash";
import runes from "runes2";
import {
  Box,
  Button,
  Stack,
  HStack,
  useToast,
  Icon,
  Text,
  Popover,
  Input,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerCloseButton,
  IconButton,
  Spacer,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Alert,
  AlertIcon,
  PopoverAnchor,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useBreakpointValue,
  FormControl,
  Textarea,
  VStack,
  Heading,
  Divider,
} from "@chakra-ui/react";
import { FiChevronLeft, FiPlus } from "react-icons/fi";
import { useSidebarContext } from "@/providers/sidebar-context";
import MessageItem, { createChunks, genLineItemWithRecords, isActive } from "@/components/items/message-item";
import MessageEditor from "@/components/items/message-editor";
import OrderMessage from "@/components/items/order-message";
import OrderActivity from "@/components/items/order-activity";
import graphQLClient from "@/api/graphql-client";
import queryClient from "@/api/query-client";
import { CANCEL_ORDER, CONFIRM_ORDER, REPROCESS_ORDER, UPDATE_DRAFT_ORDER } from "@/api/mutations/order";
import { queryOrderDetailByOrderId } from "@/api/queries/order-detail";
import { searchPriceSheetByCustomerId } from "@/api/queries/price-sheet";
import { UpdatedOrder, useOrderUpdated } from "@/hooks/subscription";
import { formatTitle, getScrollParent, getTomorrowDate } from "@/utils/formatter";
import { checkSubscriptionError } from "@/utils/subscription";
import { getUserCompany } from "@/utils/user";
import type { LineItemWithRecords, Message, OrderDetail, PriceSheetItem } from "@/utils/types";
import useProductInfo from "@/hooks/product-info";
import { HiDotsVertical } from "react-icons/hi";
import orderMessage from "@/components/items/order-message";

export async function loader({ params }: { params: Params }) {
  const {
    company: { companyId, companyName },
  } = getUserCompany();
  const { orderId } = params as { orderId: string };
  const fetchOrderDetail = queryOrderDetailByOrderId(companyId, orderId);
  const {
    order,
  }: {
    order: OrderDetail;
  } = (await queryClient.fetchQuery(fetchOrderDetail)) || {};

  return { order, companyName, companyId };
}

export default function OrderAnalyzer() {
  const { order: originalData, companyId: supplierId } =
    (useLoaderData() as { order: OrderDetail; companyName: string; companyId: string }) ?? {};
  const { nextStep } = useOutletContext<{ nextStep: () => void }>() ?? {};
  const { isOpen: isSidebarOpen, onToggle: onSidebarToggle } = useSidebarContext();
  const isBaseView = useBreakpointValue({ base: true, xl: false }, { ssr: false });
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const sParam = queryParams.get("s");
  const toast = useToast();
  const textRef = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
  const { isOpen: isCancelOpen, onOpen: onCancelOpen, onClose: onCancelClose } = useDisclosure();
  const { isOpen: isBuyerNoteOpen, onOpen: onBuyerNoteOpen, onClose: onBuyerNoteClose } = useDisclosure();
  const [buyerNote, setBuyerNote] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const cancelConfirmRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();

  const [orderStatus, setOrderStatus] = useState(originalData?.status);
  const [isInitialized, setIsInitialized] = useState(orderStatus === "Initialized");
  const [isNeedsReview, setIsNeedsReview] = useState(orderStatus === "NeedsReview");
  const [isPending, setIsPending] = useState(orderStatus === "Pending");
  const [hasCancelled, setHasCancelled] = useState(false);

  const [messages, setMessages] = useState<Message[]>([]);
  const [elRefs, setElRefs] = useState<(HTMLDivElement | null)[][]>([]);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);
  const [actionList, setActionList] = useState<LineItemWithRecords[]>([]);
  const [pos, setPos] = useState<number[]>([]);
  const [deliveryDate, setDeliveryDate] = useState<string>("");
  const [selection, setSelection] = useState<{
    top: number;
    left: number;
    width: number;
    height: number;
    text: string;
    start: number;
    end: number;
    index: number;
  } | null>(null);

  const { id: orderId, companyId, customerId } = originalData || {};
  const { data: productInfoData, fetchData: fetchProductInfo } = useProductInfo(companyId, customerId);
  //@ts-expect-error
  const expectedPrices = originalData?.metaData?.expected_prices;
  const hasNoCustomer = !originalData?.customerId;
  const msgsRef = useRef<HTMLDivElement>(null);

  const { isOpen: isLineItemOpen, onOpen: onLineItemOpen, onClose: onLineItemClose } = useDisclosure();

  const genMessages = (data: OrderDetail, useOldMsgs = true) => {
    if (data?.status === "Initialized") {
      return data.messages;
    }
    let msgs = data?.messages?.map((elem) => {
      const translationJob = data.translationJobs.find((job) => job.id === elem.jobId);
      const msg: Message = {
        id: elem.id,
        author: elem.author,
        authorType: elem.authorType,
        createdTs: elem.createdTs,
        body: elem.body,
        aiResp: elem.aiResp,
        index: elem.index,
        action: translationJob?.action,
        jobId: translationJob?.id,
        participant: elem.participant,
        type: elem.type,
        subject: elem.subject,
        mediaTextRecognitions: elem.mediaTextRecognitions,
        creationStatus: elem.creationStatus,
        creationError: elem.creationError,
      };

      // @ts-expect-error
      msg.suggestions =
        translationJob?.result?.lineItems.map((item) => {
          const lineItemSuggestions =
            item.similarSuggestions?.map((t) => ({
              productId: t.productId,
              description: t.description,
              quantity: Number(t.quantity),
              lastOrderedDate: t.lastOrderedDate,
              slug: t.slug,
            })) ?? [];
          return {
            textRange: item.textRange!,
            // isManual: item.isManual,
            isAdded: item.isManualAdd,
            lineItemSuggestions: [
              {
                productId: item.productId,
                description: item.description,
                quantity: Number(item.quantity),
                lastOrderedDate: item.lastOrderedDate,
                slug: item.slug,
              },
              ...lineItemSuggestions,
            ],
          };
        }) ?? [];
      if (useOldMsgs) {
        const oldMsg = messages.find((omsg) => omsg.id === msg.id);
        if (oldMsg?.jobId != msg.jobId) {
          msg.chunks = createChunks(msg);
        } else {
          msg.suggestions = oldMsg?.suggestions ?? [];
          msg.chunks = oldMsg?.chunks ?? createChunks(msg);
        }
      } else {
        msg.chunks = createChunks(msg);
      }
      return msg;
    });
    const orderStartIndex = _.findIndex(msgs, (elem) => !!elem.jobId);
    const orderEndIndex = _.findLastIndex(msgs, (elem) => !!elem.jobId);
    msgs = msgs?.map((elem, index) => {
      if (index < orderStartIndex || index > orderEndIndex) {
        elem.isContext = true;
      }
      return elem;
    });
    return msgs;
  };

  useEffect(() => {
    setMessages(genMessages(originalData, false));
    setOrderStatus(originalData?.status);
    setDeliveryDate(originalData?.deliveryDate ?? getTomorrowDate());
  }, [orderId, originalData]);

  useEffect(() => {
    const list = genLineItemWithRecords(messages);
    setActionList(list);
  }, [messages]);

  useEffect(() => {
    const productIds = actionList.map((elem) => elem.productId);
    fetchProductInfo(productIds);
  }, [actionList]);

  useEffect(() => {
    setIsInitialized(orderStatus === "Initialized");
    setIsNeedsReview(orderStatus === "NeedsReview");
    setIsPending(orderStatus === "Pending");
  }, [orderStatus]);

  // order cancelled
  useEffect(() => {
    (async () => {
      if (originalData?.id) {
        const orderDetail = await getOrderDetail(originalData?.id?.toString(), true);

        setOrderStatus(orderDetail?.status);
        setIsInitialized(orderDetail?.status === "Initialized");
        setIsNeedsReview(orderDetail?.status === "NeedsReview");
        setIsPending(orderStatus === "Pending");
      }
    })();
  }, [hasCancelled]);

  useEffect(() => {
    if (!pos.length) return;
    const [x, y] = pos;
    const elm = elRefs[x][y];
    if (!elm) return;
    const top = elm.offsetTop;
    const parent = getScrollParent(elm);

    if (parent) parent.scrollTo(0, top - 236);
    // setSelectedElemAttr({
    //   offsetTop: elm.offsetTop,
    //   offsetLeft: elm.offsetLeft,
    //   offsetWidth: elm.offsetWidth,
    //   offsetHeight: elm.offsetHeight,
    //   scrollTop: parent?.scrollTop ?? 0,
    // });
  }, [pos, messages]);

  useOrderUpdated(async ({ data: updatedOrder, errors }: { data?: UpdatedOrder; errors?: Error[] }) => {
    if (checkSubscriptionError(errors)) return;
    if (!updatedOrder) return;
    if (Number(updatedOrder.id) != orderId) return;
    const orderDetail = await getOrderDetail(updatedOrder.id, true);
    const needCreateChunks = orderStatus === "Initialized" && orderDetail.status === "NeedsReview";
    setOrderStatus(orderDetail.status);
    setDeliveryDate(orderDetail.deliveryDate ?? deliveryDate ?? getTomorrowDate());
    setMessages(genMessages(orderDetail, !needCreateChunks));
  });

  useHotkeys("mod+enter", () => !(isInitialized || isPending) && handleConfirmOrder(), {
    preventDefault: true,
  });

  useHotkeys("shift+n", () => onBuyerNoteOpen(), {
    preventDefault: true,
  });

  const handleMousedown = () => {
    const selection = document.getSelection();
    selection?.removeAllRanges();
    setSelection(null);
  };

  const handleSelection = () => {
    if (!isNeedsReview) return;
    const selection = document.getSelection();

    if (selection?.type === "Range") {
      const selRange = selection.getRangeAt(0);
      const ancestor = selRange.commonAncestorContainer as Element;
      const elementsWithSlice = ancestor.querySelectorAll?.(".chunk-slice");
      const elementsWithAvatar = ancestor.querySelectorAll?.(".chunk-avatar");

      // const parentEl = ancestor.parentNode;
      for (let i = 0; i < elementsWithSlice?.length; i += 1) {
        if (selection.containsNode(elementsWithSlice[i], true)) {
          setSelection(null);
          return; // if the selection contains a slice, don't do anything
        }
      }
      for (let i = 0; i < elementsWithAvatar?.length; i += 1) {
        if (selection.containsNode(elementsWithAvatar[i], true)) {
          setSelection(null);
          return; // if the selection contains a avatar, don't do anything
        }
      }
      const span = selRange.startContainer.parentElement;
      try {
        const a =
          parseInt(span?.dataset?.start ?? "0") +
          runes(span?.textContent?.substring(0, selRange.startOffset) ?? "").length;
        const b = a + runes(selection.toString()).length;
        const index = parseInt(span?.dataset?.index ?? "-1");
        const rect = selRange.getBoundingClientRect();

        if (index > -1) {
          if (messages[index]?.isContext) return;
          setSelection({
            top: rect.top,
            left: rect.left,
            width: rect.width,
            height: rect.height,
            text: selection.toString(),
            start: a,
            end: b,
            index,
          });
        }
      } catch (e) {
        console.error("Failed to handle selection");
        console.error(e);
      }
    } else {
      setSelection(null);
    }
  };

  const setChunkRefs = (index: number, refs: (HTMLDivElement | null)[]) => {
    elRefs[index] = refs;
    setElRefs(elRefs);
  };

  // autosave when on blur
  const deselectItem = () => {
    setPos([]);
    onClose();
    const originalMessages = genMessages(originalData, false);
    const hasChanged = !_.isEqual(genLineItemWithRecords(originalMessages), genLineItemWithRecords(messages));

    setTimeout(() => {
      (async () => {
        hasChanged && (await handleSaveOrder());
      })();
    }, 100);
  };

  const getNextPos = (x: number, y: number): number[] => {
    if (y < elRefs[x].length - 1) {
      y++;
    } else {
      if (x < elRefs.length - 1) {
        x++;
        y = 0;
      } else {
        return [];
      }
    }
    if (elRefs[x][y]?.classList?.contains("chunk-slice")) return [x, y];
    return getNextPos(x, y);
  };

  const selectNextItem = () => {
    const [x = 0, y = 0] = pos;
    const next = getNextPos(x, y);
    if (next.length) {
      setPos(next);
    } else {
      deselectItem();
    }
  };

  const getPrevPos = (x: number, y: number): number[] => {
    if (y > 0) {
      y--;
    } else {
      if (x > 0) {
        x--;
        y = elRefs[x].length - 1;
      } else {
        return [];
      }
    }
    if (elRefs[x][y]?.classList?.contains("chunk-slice")) return [x, y];
    return getPrevPos(x, y);
  };

  const selectPrevItem = () => {
    const [x, y] = pos;
    const prev = getPrevPos(x, y);
    if (prev.length) {
      setPos(prev);
    } else {
      deselectItem();
    }
  };

  const removeItem = (pos: number[]) => {
    if (!isNeedsReview) return;
    const [x, y] = pos;
    const chunk = messages[x].chunks?.[y];
    const index = messages[x]!.suggestions?.findIndex((elem) => elem.chunk === chunk) ?? 0;
    messages[x].suggestions!.splice(index, 1);
    messages[x].chunks = createChunks(messages[x]);
    setMessages((prevState) =>
      prevState.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            suggestions: messages[index]!.suggestions,
            chunks: messages[index].chunks,
          };
        }
        return item;
      })
    );
    deselectItem();
  };

  const addSelectedChunk = () => {
    const suggestion = {
      isAdded: true,
      textRange: [selection!.start, selection!.end],
      lineItemSuggestions: [],
      isManual: true,
    };
    const index = selection!.index;
    let idx = messages[index]!.suggestions!.findIndex((s: { textRange: number[] }) => {
      return s.textRange[0] >= suggestion.textRange[1];
    });
    if (idx > -1) {
      messages[index]!.suggestions!.splice(idx, 0, suggestion);
    } else {
      messages[index]!.suggestions!.push(suggestion);
    }
    messages[index].chunks = createChunks(messages[index]);
    idx =
      messages[index].chunks?.findIndex(
        (t: { textRange: number[] }) => t.textRange[0] === selection!.start && t.textRange[1] === selection!.end
      ) ?? 0;
    setMessages((prevState) =>
      prevState.map((item, idx) => {
        if (idx === index) {
          return {
            ...item,
            suggestions: messages[index]!.suggestions,
            chunks: messages[index].chunks,
          };
        }
        return item;
      })
    );
    setPos([index, idx]);
    const selectionDoc = document.getSelection();
    selectionDoc?.removeAllRanges();
    setSelection(null);
  };

  const mutateUpdateDraftOrder = useMutation<
    unknown,
    unknown,
    { supplierPid: string; orderId: number; lineItems: []; editedJobs: []; deliveryDate: string }
  >(
    async ({ supplierPid, orderId, lineItems, editedJobs, deliveryDate }) => {
      return await graphQLClient.request(UPDATE_DRAFT_ORDER, {
        supplierPid,
        orderId,
        lineItems,
        editedJobs,
        deliveryDate,
        buyerNote,
      });
    },
    {
      onSuccess: () => {},
      onError: (e) => {
        toast({
          // @ts-expect-error
          title: e.response?.errors
            // @ts-expect-error
            ?.map((e) => e.message)
            .join(", ")
            .slice(0, -1),
          description: "Please try again",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
      },
    }
  );

  const mutateConfirmOrder = useMutation<
    unknown,
    unknown,
    { supplierPid: string; orderId: number; lineItems: []; editedJobs: []; deliveryDate: string; buyerNote: string }
  >(
    async ({ supplierPid, orderId, lineItems, editedJobs, deliveryDate }) => {
      return await graphQLClient.request(CONFIRM_ORDER, {
        supplierPid,
        orderId,
        lineItems,
        editedJobs,
        deliveryDate,
        buyerNote,
      });
    },
    {
      onSuccess: () => {},
      onError: (e) => {
        toast({
          // @ts-expect-error
          title: e.response?.errors
            // @ts-expect-error
            ?.map((e) => e.message)
            .join(", ")
            .slice(0, -1),
          description: "Please try again",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
      },
    }
  );

  const mutateReprocessOrder = useMutation<unknown, unknown, { supplierId: string; orderId: number }>(
    async ({ supplierId, orderId }) => {
      return await graphQLClient.request(REPROCESS_ORDER, {
        supplierId,
        orderId,
      });
    },
    {
      onSuccess: () => {},
      onError: (e) => {
        toast({
          // @ts-expect-error
          title: e.response?.errors
            // @ts-expect-error
            ?.map((e) => e.message)
            .join(", ")
            .slice(0, -1),
          description: "Please try again",
          status: "error",
          duration: 5000,
          isClosable: true,
          onCloseComplete: () => {},
        });
      },
    }
  );

  const handleSaveOrder = async () => {
    let lineItems: {
      product_id?: string;
      slug?: string;
      description?: string;
      quantity: number;
      last_ordered_date?: string;
      similar_suggestions?: string;
      unit?: string;
      is_manual_add?: boolean;
      is_manual?: boolean;
    }[] = [];
    const editedJobs: { id: number | undefined; message_id: number; action: string; result: string; text: string }[] =
      [];
    try {
      setIsSaveLoading(true);
      // is_manual_add
      messages?.forEach((msg) => {
        if (msg.suggestions?.length) {
          const result: {
            product_id: string | undefined;
            slug: string | undefined;
            description: string | undefined;
            quantity: number | undefined;
            last_ordered_date: string | undefined;
            unit: string;
            score: number;
            similar_suggestions: string;
            text_range: number[];
            is_manual: boolean | undefined;
            is_manual_add: boolean | undefined;
          }[] = [];
          msg?.suggestions?.forEach((sug) => {
            const chunk = sug.chunk;
            // range error
            if (chunk && isActive(chunk)) {
              const { productId, description, quantity, lastOrderedDate } = chunk?.isManual
                ? chunk.manual!
                : chunk.options![0];
              //chunk!.options.filter((elem) => elem.productId !== productId)
              const similarSuggestions = chunk!.options
                ?.filter((item, index, self) => index === self.findIndex((t) => t.productId === item.productId))
                .map((elem) => ({
                  product_id: elem.productId,
                  slug: elem.slug,
                  description: elem.description,
                  quantity: elem.quantity,
                  lastOrderedDate: elem.lastOrderedDate,
                }));

              const selectedProduct = chunk?.isManual
                ? {
                    productId,
                    description,
                    qty: quantity,
                    lastOrderedDate,
                  }
                : // @ts-expect-error
                  chunk?.options?.[chunk?.selected];
              result.push({
                product_id: selectedProduct.productId,
                slug: selectedProduct.slug,
                description: selectedProduct.description,
                quantity: selectedProduct.quantity ?? selectedProduct.qty, // Note: quantity when it's detected, qty when it's manually added
                last_ordered_date: selectedProduct.lastOrderedDate,
                unit: "",
                score: 0,
                similar_suggestions: JSON.stringify(similarSuggestions),
                text_range: sug.textRange,
                is_manual: chunk?.isManual,
                is_manual_add: chunk.isAdded,
              });

              const oldItem = lineItems.find((elem: { product_id?: string }) => elem.product_id === productId);
              const qty = Number(quantity);
              let orderItem: {
                product_id: string | undefined;
                slug: string | undefined;
                description: string | undefined;
                quantity: number;
                last_ordered_date: string | undefined;
                similar_suggestions: string;
                unit: string;
                is_manual_add?: boolean;
                is_manual?: boolean;
              };
              switch (msg.action) {
                case "CreateOrder":
                  orderItem = {
                    product_id: selectedProduct.productId,
                    slug: selectedProduct.slug,
                    description: selectedProduct.description,
                    similar_suggestions: JSON.stringify(similarSuggestions),
                    quantity: qty,
                    last_ordered_date: selectedProduct.lastOrderedDate,
                    unit: "",
                    // unit_price: "",
                  };
                  if (chunk.isAdded) {
                    orderItem.is_manual_add = true;
                  }
                  if (chunk?.isManual) {
                    orderItem.is_manual = true;
                  }
                  lineItems.push(orderItem);
                  break;
                case "AddItem":
                  if (oldItem) {
                    oldItem.quantity += qty;
                  } else {
                    orderItem = {
                      product_id: selectedProduct.productId,
                      slug: selectedProduct.slug,
                      description: selectedProduct.description,
                      similar_suggestions: JSON.stringify(similarSuggestions),
                      quantity: qty,
                      last_ordered_date: selectedProduct.lastOrderedDate,
                      unit: "",
                      // unit_price: "",
                    };
                    if (chunk.isAdded) {
                      orderItem.is_manual_add = true;
                    }
                    if (chunk?.isManual) {
                      orderItem.is_manual = true;
                    }
                    lineItems.push(orderItem);
                  }
                  break;
                case "UpdateQty":
                  if (oldItem) {
                    oldItem.quantity = qty;
                  } else {
                    orderItem = {
                      product_id: selectedProduct.productId,
                      slug: selectedProduct.slug,
                      description: selectedProduct.description,
                      similar_suggestions: JSON.stringify(similarSuggestions),
                      quantity: qty,
                      last_ordered_date: selectedProduct.lastOrderedDate,
                      unit: "",
                      // unit_price: "",
                    };
                    if (chunk.isAdded) {
                      orderItem.is_manual_add = true;
                    }
                    if (chunk?.isManual) {
                      orderItem.is_manual = true;
                    }
                    lineItems.push(orderItem);
                  }
                  break;
                case "DeleteItem":
                  if (oldItem) {
                    oldItem.quantity = Math.max(0, oldItem.quantity - qty);
                    if (chunk?.isManual) {
                      oldItem.is_manual = true;
                    }
                  }
                  break;
                default:
                  break;
              }
            }
          });
          if (result) {
            editedJobs.push({
              id: msg.jobId ?? 0,
              message_id: msg.id,
              action: msg.action ?? "AddItem",
              result: JSON.stringify({ line_items: result }),
              text: msg.body,
            });
          }
        }
      });
      lineItems = lineItems.filter((elem: { quantity: number }) => elem.quantity != 0);
      // search all product
      const filterIds = lineItems
        .filter((item) => item.product_id)
        .map((item) => item.product_id!)
        .join(",");
      const fetchSuggestions = searchPriceSheetByCustomerId(companyId, customerId, "", filterIds, 0, 100, false);
      const { products }: { products: PriceSheetItem[] } = (await queryClient.fetchQuery(fetchSuggestions)) ?? {};
      if (products?.length) {
        const productMap = new Map();
        products?.forEach((item) => {
          productMap.set(item.id, item);
        });
        lineItems?.forEach(
          (item: {
            unit_price?: number | null;
            product_price?: number | null;
            package_size?: string;
            product_id?: string;
            slug?: string;
            description?: string;
            quantity: number;
            last_ordered_date?: string;
            similar_suggestions?: string;
            unit?: string;
            is_manual_add?: boolean;
            is_manual?: boolean;
          }) => {
            if (productMap.has(item.product_id)) {
              const product: PriceSheetItem = productMap.get(item.product_id);
              item.unit_price = product.unitPrice == null ? null : product.unitPrice ?? 0;
              item.product_price = product.productPrice == null ? null : product.productPrice;
              item.package_size = (product.packageSize ?? "").toString();
            }
          }
        );
      }

      await mutateUpdateDraftOrder.mutateAsync({
        supplierPid: companyId,
        orderId: orderId,
        //@ts-expect-error
        lineItems,
        //@ts-expect-error
        editedJobs,
        deliveryDate,
      });
      toast({
        title: `Your changes have been saved!`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsSaveLoading(false);
    }
  };

  const handleConfirmOrder = async () => {
    let lineItems: {
      product_id?: string;
      slug?: string;
      description?: string;
      quantity: number;
      last_ordered_date?: string;
      similar_suggestions?: string;
      unit?: string;
      is_manual_add?: boolean;
      is_manual?: boolean;
    }[] = [];
    const editedJobs: { id: number | undefined; message_id: number; action: string; result: string; text: string }[] =
      [];
    try {
      onConfirmClose();
      setIsConfirmLoading(true);
      // is_manual_add
      messages?.forEach((msg) => {
        if (msg.suggestions?.length) {
          const result: {
            product_id: string | undefined;
            slug: string | undefined;
            description: string | undefined;
            quantity: number | undefined;
            last_ordered_date: string | undefined;
            unit: string;
            score: number;
            similar_suggestions: string;
            text_range: number[];
            is_manual: boolean | undefined;
            is_manual_add: boolean | undefined;
          }[] = [];
          msg?.suggestions?.forEach((sug) => {
            const chunk = sug.chunk;
            // range error
            if (chunk && isActive(chunk)) {
              const { productId, description, quantity, lastOrderedDate } = chunk?.isManual
                ? chunk.manual!
                : chunk.options![0];
              const similarSuggestions = chunk!.options
                ?.filter((elem) => elem.productId !== productId)
                .map((elem) => ({
                  product_id: elem.productId,
                  slug: elem.slug,
                  description: elem.description,
                  quantity: elem.quantity,
                  lastOrderedDate: elem.lastOrderedDate,
                }));

              const selectedProduct = chunk?.isManual
                ? {
                    productId,
                    description,
                    qty: quantity,
                    lastOrderedDate,
                  }
                : // @ts-expect-error
                  chunk?.options?.[chunk?.selected];
              result.push({
                product_id: selectedProduct.productId,
                slug: selectedProduct.slug,
                description: selectedProduct.description,
                quantity: selectedProduct.quantity ?? selectedProduct.qty, // Note: quantity when it's detected, qty when it's manually added
                last_ordered_date: selectedProduct.lastOrderedDate,
                unit: "",
                score: 0,
                similar_suggestions: JSON.stringify(similarSuggestions),
                text_range: sug.textRange,
                is_manual: chunk?.isManual,
                is_manual_add: chunk.isAdded,
              });

              const oldItem = lineItems.find((elem: { product_id?: string }) => elem.product_id === productId);
              const qty = Number(quantity);
              let orderItem: {
                product_id: string | undefined;
                slug: string | undefined;
                description: string | undefined;
                quantity: number;
                last_ordered_date: string | undefined;
                similar_suggestions: string;
                unit: string;
                is_manual_add?: boolean;
                is_manual?: boolean;
              };
              switch (msg.action) {
                case "CreateOrder":
                  orderItem = {
                    product_id: selectedProduct.productId,
                    slug: selectedProduct.slug,
                    description: selectedProduct.description,
                    similar_suggestions: JSON.stringify(similarSuggestions),
                    quantity: qty,
                    last_ordered_date: selectedProduct.lastOrderedDate,
                    unit: "",
                    // unit_price: "",
                  };
                  if (chunk.isAdded) {
                    orderItem.is_manual_add = true;
                  }
                  if (chunk?.isManual) {
                    orderItem.is_manual = true;
                  }
                  lineItems.push(orderItem);
                  break;
                case "AddItem":
                  if (oldItem) {
                    oldItem.quantity += qty;
                  } else {
                    orderItem = {
                      product_id: selectedProduct.productId,
                      slug: selectedProduct.slug,
                      description: selectedProduct.description,
                      similar_suggestions: JSON.stringify(similarSuggestions),
                      quantity: qty,
                      last_ordered_date: selectedProduct.lastOrderedDate,
                      unit: "",
                      // unit_price: "",
                    };
                    if (chunk.isAdded) {
                      orderItem.is_manual_add = true;
                    }
                    if (chunk?.isManual) {
                      orderItem.is_manual = true;
                    }
                    lineItems.push(orderItem);
                  }
                  break;
                case "UpdateQty":
                  if (oldItem) {
                    oldItem.quantity = qty;
                  } else {
                    orderItem = {
                      product_id: selectedProduct.productId,
                      slug: selectedProduct.slug,
                      description: selectedProduct.description,
                      similar_suggestions: JSON.stringify(similarSuggestions),
                      quantity: qty,
                      last_ordered_date: selectedProduct.lastOrderedDate,
                      unit: "",
                      // unit_price: "",
                    };
                    if (chunk.isAdded) {
                      orderItem.is_manual_add = true;
                    }
                    if (chunk?.isManual) {
                      orderItem.is_manual = true;
                    }
                    lineItems.push(orderItem);
                  }
                  break;
                case "DeleteItem":
                  if (oldItem) {
                    oldItem.quantity = Math.max(0, oldItem.quantity - qty);
                    if (chunk?.isManual) {
                      oldItem.is_manual = true;
                    }
                  }
                  break;
                default:
                  break;
              }
            }
          });
          if (result) {
            editedJobs.push({
              id: msg.jobId ?? 0,
              message_id: msg.id,
              action: msg.action ?? "AddItem",
              result: JSON.stringify({ line_items: result }),
              text: msg.body,
            });
          }
        }
      });
      lineItems = lineItems.filter((elem: { quantity: number }) => elem.quantity != 0);
      // search all product
      const filterIds = lineItems
        .filter((item) => item.product_id)
        .map((item) => item.product_id!)
        .join(",");
      const fetchSuggestions = searchPriceSheetByCustomerId(companyId, customerId, "", filterIds, 0, 100, false);
      const { products }: { products: PriceSheetItem[] } = (await queryClient.fetchQuery(fetchSuggestions)) ?? {};
      if (products?.length) {
        const productMap = new Map();
        products?.forEach((item) => {
          productMap.set(item.id, item);
        });
        lineItems?.forEach(
          (item: {
            unit_price?: number | null;
            product_price?: number | null;
            package_size?: string;
            product_id?: string;
            slug?: string;
            description?: string;
            quantity: number;
            last_ordered_date?: string;
            similar_suggestions?: string;
            unit?: string;
            is_manual_add?: boolean;
            is_manual?: boolean;
          }) => {
            if (productMap.has(item.product_id)) {
              const product: PriceSheetItem = productMap.get(item.product_id);
              item.unit_price = product.unitPrice == null ? null : product.unitPrice ?? 0;
              item.product_price = product.productPrice == null ? null : product.productPrice;
              item.package_size = (product.packageSize ?? "").toString();
            }
          }
        );
      }
      await mutateConfirmOrder.mutateAsync({
        supplierPid: companyId,
        orderId: orderId,
        //@ts-expect-error
        lineItems,
        //@ts-expect-error
        editedJobs,
        deliveryDate,
      });
      nextStep();
    } catch (err) {
      console.log(err);
    } finally {
      setIsConfirmLoading(false);
    }
  };

  const handleReprocessOrder = async () => {
    try {
      await mutateReprocessOrder.mutateAsync({
        supplierId: companyId,
        orderId: orderId,
      });
      toast({
        title: `This order has been reprocessed!`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useHotkeys(
    "tab",
    () => {
      onOpen();
      if (!pos.length && elRefs.length) {
        setPos(getNextPos(0, 0));
      }
    },
    { enableOnFormTags: ["input"], preventDefault: true }
  );

  useHotkeys(
    "shift+tab",
    () => {
      onOpen();
      if (!pos.length && elRefs.length && elRefs[elRefs.length - 1].length) {
        setPos([elRefs.length - 1, elRefs[elRefs.length - 1].length - 1]);
      }
    },
    { enableOnFormTags: ["input"], preventDefault: true }
  );

  const handleMessageAdded = (message: any) => {
    if (message) {
      setMessages((prevState) => {
        return [
          ...prevState,
          {
            id: message.id,
            creationStatus: message.creation_status,
            creationError: message.creation_error,
            createdTs: message.created_ts,
          } as Message,
        ];
      });
      setTimeout(() => {
        if (msgsRef.current) {
          msgsRef.current.scrollTop = msgsRef.current.scrollHeight;
        }
      }, 50);
    }
  };

  const mutateCancelOrder = useMutation<unknown, unknown, { supplierId: string; orderId: number }>(
    async ({ supplierId, orderId }) => {
      return await graphQLClient.request(CANCEL_ORDER, {
        supplierId,
        orderId,
      });
    }
  );

  async function handleCancelOrder() {
    try {
      setIsLoading(true);
      await mutateCancelOrder.mutateAsync({
        supplierId,
        orderId,
      });
      toast({
        title: "Order cancelled!",
        description: `You have successfully cancelled order ${orderId} for ${originalData?.customerName}`,
        status: "info",
        duration: 3000,
        isClosable: true,
        onCloseComplete: () => {},
      });
      setHasCancelled(true);
      onCancelClose();
    } catch (err) {
      console.log(err);
      toast({
        title: "Failed to cancel order",
        description: `There has been an error cancelling this order for ${originalData?.customerName}, try again later.`,
        status: "error",
        duration: 3000,
        isClosable: true,
        onCloseComplete: () => {},
      });
    } finally {
      setIsLoading(false);
    }
  }

  async function handleBuyerNote() {
    try {
      setIsLoading(true);
      await handleSaveOrder();
      toast({
        title: "Added a buyer note!",
        description: `You have successfully added a buyer note from ${originalData?.customerName} for order ${orderId}.`,
        status: "info",
        duration: 3000,
        isClosable: true,
        onCloseComplete: () => {},
      });
      onBuyerNoteClose();
      setBuyerNote("");
    } catch (err) {
      console.log(err);
      toast({
        title: "Failed to add a buyer note.",
        description: `There has been an error adding a buyer note for ${orderId}, try again later.`,
        status: "error",
        duration: 3000,
        isClosable: true,
        onCloseComplete: () => {},
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <HStack justifyContent="space-between" mb={{ base: 4, lg: 0 }} display="flex">
        <HStack w="full" alignItems="center" mt={hasNoCustomer && isBaseView ? "2" : "-8"}>
          {/* <CancelOrder
            orderId={originalData?.id}
            supplierId={supplierId}
            customerName={originalData?.customerName}
            isCancellable={!["Cancelled", "Confirmed"].includes(orderStatus)}
            setHasCancelled={setHasCancelled}
          /> */}
          {!(hasNoCustomer && isBaseView) && <Spacer display={{ base: "none", lg: "flex" }} />}
          <HStack>
            <Text color={!isNeedsReview ? "gray.400" : "gray.600"}>Delivery Date:</Text>
            <Input
              placeholder="Select Delivery Date"
              size="sm"
              type="date"
              w="240"
              value={deliveryDate ?? ""}
              onChange={(e) => {
                setDeliveryDate(e.target.value);
              }}
              isDisabled={!isNeedsReview}
            />
          </HStack>
          {/* <Button
            variant="outline"
            colorScheme="primary"
            // bg="primary.400"
            w={{ base: "24", lg: "28" }}
            onClick={handleSaveOrder}
            isLoading={isSaveLoading}
            isDisabled={!isNeedsReview}
            ml={{ base: 0, lg: 4 }}
          >
            Save Changes
          </Button> */}
          {/* cancel modal */}
          <Modal isOpen={isBuyerNoteOpen} onClose={onBuyerNoteClose} isCentered initialFocusRef={textRef}>
            <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
            <ModalContent maxW={{ base: "90%", lg: "md" }}>
              <ModalHeader>Buyer note for order #{orderId}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {originalData?.buyerNote && (
                  <VStack alignItems="start" p="2" borderWidth="thin" borderRadius="md" mb="4">
                    <Heading color="gray.600" fontSize="xs">
                      Current Note
                    </Heading>
                    <Text color="gray.800"> {originalData?.buyerNote}</Text>
                  </VStack>
                )}
                <FormControl mt="2">
                  <Textarea
                    value={buyerNote}
                    onChange={(e) => setBuyerNote(e.target.value)}
                    placeholder="Enter a buyer note"
                    size="sm"
                    ref={textRef}
                  />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button variant="outline" mr={3} onClick={onBuyerNoteClose}>
                  Cancel
                </Button>
                <Button variant="primary" color="white" onClick={handleBuyerNote} isLoading={isLoading}>
                  Save Note
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>

          {/* cancel modal */}
          <Modal isOpen={isCancelOpen} onClose={onCancelClose} isCentered>
            <ModalOverlay bg="none" backdropFilter="auto" backdropBlur="2px" />
            <ModalContent maxW={{ base: "90%", lg: "md" }}>
              <ModalHeader>Cancel order #{orderId}</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                Are you sure you want to cancel order #{orderId} for {originalData?.customerName}?
              </ModalBody>

              <ModalFooter>
                <Button variant="outline" mr={3} onClick={onCancelClose}>
                  No, keep this order
                </Button>
                <Button colorScheme="red" onClick={handleCancelOrder} isLoading={isLoading}>
                  Cancel Order
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          {/* confirm popover */}
          <Popover defaultIsOpen={isInitialized} closeOnBlur={false} closeOnEsc={false}>
            <PopoverTrigger>
              <Button
                colorScheme="primary"
                bg="primary.400"
                w={{ base: "24", lg: "28" }}
                onClick={
                  orderStatus == "Confirmed"
                    ? () => navigate(`/orders/${orderId}/summary${sParam ? `?s=${sParam}` : ""}`)
                    : onConfirmOpen
                }
                isLoading={isConfirmLoading}
                isDisabled={orderStatus == "Pending" || orderStatus == "Initialized"}
                ml={{ base: 0, lg: 4 }}
              >
                {orderStatus == "Confirmed" ? "View Summary" : "Confirm Order"}
              </Button>
            </PopoverTrigger>
            {isInitialized && (
              <PopoverContent mr="8" w="fit-content" _focusVisible={{ outline: "none" }}>
                <PopoverArrow />
                <PopoverBody>
                  <Alert status="info">
                    <AlertIcon />
                    The conversation is still being processed by our AI system
                  </Alert>
                </PopoverBody>
              </PopoverContent>
            )}
          </Popover>
          {orderStatus !== "Confirmed" && (
            <Menu closeOnSelect={false}>
              <MenuButton
                as={Button}
                variant="unstyled"
                _hover={{ color: "gray.800" }}
                color="gray.800"
                _focusVisible={{ outline: "none" }}
                borderWidth="thin"
                borderColor="gray.200"
              >
                <Icon as={HiDotsVertical} boxSize="3" />
              </MenuButton>
              <MenuList zIndex={12} minW="16" mt="0">
                <MenuOptionGroup title="" defaultValue="1" type="radio" mt="0" p="1">
                  {["cancelOrder", "saveOrder", "reprocessOrder"].map((elem, index) => (
                    <MenuItemOption
                      value={elem}
                      fontSize="xs"
                      _hover={{ bgColor: "gray.50", color: "gray.800" }}
                      _focus={{ bgColor: "gray.50", color: "gray.800" }}
                      key={index}
                      _checked={{ color: "gray.800" }}
                      icon={<></>}
                      mr="4"
                      isDisabled={
                        (elem == "saveOrder" && orderStatus !== "NeedsReview") ||
                        (elem == "reprocessOrder" && orderStatus == "Confirmed")
                      }
                      onClick={() => {
                        switch (elem) {
                          case "cancelOrder":
                            onCancelOpen();
                            break;

                          case "saveOrder":
                            handleSaveOrder();
                            break;
                          case "reprocessOrder":
                            handleReprocessOrder();
                            break;

                          default:
                            break;
                        }
                      }}
                    >
                      {formatTitle(elem)}
                    </MenuItemOption>
                  ))}
                  <Divider w="75%" mx="auto" my="2" />
                  <MenuItemOption
                    value={"addBuyerNote"}
                    fontSize="xs"
                    _hover={{ bgColor: "gray.50", color: "gray.800" }}
                    _focus={{ bgColor: "gray.50", color: "gray.800" }}
                    _checked={{ color: "gray.800" }}
                    icon={<></>}
                    mr="4"
                    isDisabled={orderStatus !== "NeedsReview"}
                    onClick={() => {
                      onBuyerNoteOpen();
                    }}
                  >
                    {formatTitle(originalData?.buyerNote ? "updateBuyerNote" : "addBuyerNote")}
                  </MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          )}
        </HStack>
      </HStack>
      {!isSidebarOpen && (
        <HStack mt="4">
          <IconButton
            onClick={() => {
              navigate(`/orders`);
              // onSidebarToggle();
            }}
            variant="outline"
            size="sm"
            icon={<Icon as={FiChevronLeft} boxSize="4" color="gray.600" />}
            aria-label="Toggle Sidebar"
            borderRadius="lg"
            borderColor="gray.200"
            bgColor="white"
          />
          <Spacer />
          {isNeedsReview && (
            <>
              <Text>Delivery Date:</Text>
              <Input
                placeholder="Select Delivery Date"
                size="sm"
                type="date"
                w="240"
                value={deliveryDate ?? ""}
                onChange={(e) => {
                  setDeliveryDate(e.target.value);
                }}
              />
              <Spacer />
            </>
          )}
          {actionList.length > 0 && (
            <IconButton
              onClick={onLineItemOpen}
              variant="outline"
              size="sm"
              icon={<Icon as={BiFoodMenu} boxSize="5" color="gray.600" />}
              aria-label="Toggle Sidebar"
              borderRadius="lg"
              borderColor="gray.200"
              bgColor="white"
            />
          )}
        </HStack>
      )}
      <Box
        mt="4"
        borderRadius="lg"
        borderColor="gray.100"
        borderWidth="thin"
        h={{ base: "auto", lg: "calc(100vh - 8rem)" }}
        display="flex"
        flexDir="row"
        position="relative"
        overflow="hidden"
      >
        <Box position="relative" flex="1" minW={{ base: "xs", lg: "sm" }}>
          <Popover
            placement="right"
            onOpen={onOpen}
            onClose={deselectItem}
            isOpen={isOpen}
            closeOnBlur={false}
            closeOnEsc={false}
          >
            <PopoverAnchor>
              <Box pos="absolute" right="-352" top="0" zIndex="-1" bg="red"></Box>
            </PopoverAnchor>
            <Stack
              spacing="2"
              onMouseDown={handleMousedown}
              onMouseUp={handleSelection}
              px={{ base: "2", lg: "4" }}
              py={{ base: "2", lg: "4" }}
              h={{ base: "calc(100vh - 19rem)", lg: "100%" }}
              overflowY="auto"
            >
              <Stack flex="1" mb="2" ref={msgsRef} spacing="2">
                {messages?.map((message, index) => (
                  <MessageItem
                    pos={pos}
                    data={message}
                    productInfoData={productInfoData}
                    index={index}
                    key={index}
                    setChunkRefs={setChunkRefs}
                    setPos={setPos}
                    prevAuthor={index > 0 ? messages[index - 1].author + messages[index - 1].authorType : null}
                    prevTimestamp={index > 0 ? messages[index - 1].createdTs : null}
                  />
                ))}
              </Stack>
              <HStack>
                <OrderMessage
                  orderId={originalData?.id}
                  customerName={originalData?.customerName}
                  isAddable={!["Cancelled", "Confirmed"].includes(orderStatus)}
                  onMessageAdded={handleMessageAdded}
                />
              </HStack>
            </Stack>

            {selection && (
              <Box
                position="fixed"
                top={selection.top - 40}
                left={selection.left + selection.width / 2}
                transform="auto"
                translateX="-50%"
                zIndex="1000"
                px="6px"
                py="2px"
                borderRadius="4"
                bgColor="#131313"
                boxShadow="0 1px 8px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12);"
                _after={{
                  content: "' '",
                  width: "0",
                  height: "0",
                  borderStyle: "solid",
                  borderWidth: "6px 6px 0 6px;",
                  borderColor: "#131313 transparent transparent transparent;",
                  position: "absolute",
                  top: "100%",
                  left: "50%",
                  transform: "translatex(-50%)",
                }}
              >
                <Button
                  leftIcon={<FiPlus />}
                  bgColor="transparent"
                  color="white"
                  _hover={{ bgColor: "gray.800" }}
                  onClick={() => {
                    onOpen();
                    addSelectedChunk();
                  }}
                >
                  Add Item
                </Button>
              </Box>
            )}
            {!(isInitialized || isPending) && pos.length > 0 && (
              <MessageEditor
                orderStatus={orderStatus}
                messages={messages}
                setMessages={setMessages}
                pos={pos}
                // elmAttr={selectedElemAttr}
                deselectItem={deselectItem}
                selectNextItem={selectNextItem}
                selectPrevItem={selectPrevItem}
                removeItem={removeItem}
                productInfoData={productInfoData}
              />
            )}
          </Popover>
        </Box>
        {isSidebarOpen && (
          <OrderActivity
            orderStatus={orderStatus}
            list={actionList}
            productInfoData={productInfoData}
            expectedPrices={expectedPrices}
            supplierId={supplierId}
            setPos={setPos}
            onOpen={onOpen}
            onBuyerNoteOpen={onBuyerNoteOpen}
            buyerNote={originalData?.buyerNote}
          />
        )}
      </Box>

      <AlertDialog isCentered isOpen={isConfirmOpen} leastDestructiveRef={cancelConfirmRef} onClose={onConfirmClose}>
        <AlertDialogOverlay>
          <AlertDialogContent w="90%">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Order
            </AlertDialogHeader>
            <AlertDialogBody>
              After confirmation, no further changes can be made. Any additional items will be added to a new order.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelConfirmRef} onClick={onConfirmClose}>
                Cancel
              </Button>
              <Button colorScheme="primary" ml={3} isLoading={isConfirmLoading} onClick={handleConfirmOrder}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Drawer placement="bottom" size="xl" onClose={onLineItemClose} isOpen={isLineItemOpen}>
        <DrawerOverlay />
        <DrawerContent height="90vh">
          <DrawerCloseButton />
          <DrawerBody>
            <OrderActivity
              orderStatus={orderStatus}
              list={actionList}
              productInfoData={productInfoData}
              expectedPrices={expectedPrices}
              supplierId={supplierId}
              setPos={setPos}
              onOpen={onOpen}
              onBuyerNoteOpen={onBuyerNoteOpen}
              buyerNote={buyerNote}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
async function getOrderDetail(orderId: string, invalidateCache: boolean = false) {
  // Note: the invalidateCache argument that passed in queryOrderDetailByOrderId is not work, use invalidateQueries instead
  if (invalidateCache) {
    await queryClient.invalidateQueries(["orderDetail", getUserCompany().company.companyId, orderId]);
  }
  const {
    order: orderDetail,
  }: {
    order: OrderDetail;
  } = (await queryClient.fetchQuery(queryOrderDetailByOrderId(getUserCompany().company.companyId, orderId))) ?? {};
  return orderDetail;
}
