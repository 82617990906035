import {
  Box,
  Flex,
  Text,
  Divider,
  Popover,
  PopoverContent,
  PopoverBody,
  PopoverTrigger,
  PopoverAnchor,
  Icon,
  Checkbox,
  Button,
  Stack,
  useDisclosure,
  Image,
  Input,
  Skeleton,
  AspectRatio,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Spinner,
  useToast,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Alert,
  AlertIcon,
  CloseButton,
  AlertDescription,
} from "@chakra-ui/react";
import { Params, useLoaderData, useRevalidator, useNavigation, useNavigate } from "react-router-dom";
import { graphQLClientWithoutAuth, graphQLClientWithAuth } from "@/api/graphql-client";
import { SEARCH_PRICE_SHEET_BY_CUSTOMER_ID_QUERY } from "@/api/queries/price-sheet";
import { ORDER_DETAIL_WITHOUT_AUTH, UPDATE_DRAFT_ORDER, CONFIRM_ORDER } from "@/api/mutations/order";
import {
  OrderDetailWithoutAuthAltCase,
  OrderDetailWithoutAuth,
  OrderTranslationJobsAltCase,
  OrderLineItem,
  OrderLineItemAltCase,
  CompanyAltCase,
  PriceSheetItem,
} from "@/utils/types";
import { formatNumber, formatAmount, getTomorrowDate, formatDate } from "@/utils/formatter";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { useEffect, useState, Fragment, useRef, useMemo } from "react";
import { MdDelete, MdClear } from "react-icons/md";
import DefaultProductImage from "/default-product-img.jpg";
import { AsyncSelect } from "chakra-react-select";
import { getCookie, setCookie } from "@butter-technologies/dish";
import _ from "lodash";
import { getUserCompany } from "@/utils/user";

const tipPromptKEY = "mobile_view_item_tip_prompt_hide";
const redirectUrlKEY = "mobile_view_redirect_url";

type ProductOption = PriceSheetItem & { label: string; value: string };

export async function loader({
  params,
}: {
  params: Params;
}): Promise<{ order?: OrderDetailWithoutAuth; token?: string; filterIds?: string[] }> {
  const { code } = params as { code: string };
  // {supplier_pid}|{customer_id}|{order_id}
  try {
    const [supplierId, customerId, orderId] = window.atob(code).split("|");
    const {
      ai: { order_detail_without_auth },
    }: { ai: { order_detail_without_auth: OrderDetailWithoutAuthAltCase } } = await graphQLClientWithoutAuth.request(
      ORDER_DETAIL_WITHOUT_AUTH,
      {
        customerId,
        supplierId,
        orderId: Number(orderId),
      }
    );
    const filterIds = new Set<string>();

    const order = {
      id: order_detail_without_auth.id,
      customerName: order_detail_without_auth.customer_name,
      supplierPid: order_detail_without_auth.supplier_pid,
      customerId: order_detail_without_auth.customer_id,
      status: order_detail_without_auth.status,
      lineItems: order_detail_without_auth.line_items.map((item) => {
        let similarSuggestions: OrderLineItemAltCase[] = [];
        if (typeof item.similar_suggestions === "string") {
          try {
            similarSuggestions = JSON.parse(item.similar_suggestions);
          } catch (err) {
            console.log(err);
          }
        } else {
          similarSuggestions = item.similar_suggestions ?? [];
        }
        filterIds.add(item.product_id);
        const suggestions = similarSuggestions?.map((t) => {
          filterIds.add(t.product_id);
          return {
            productId: t.product_id,
            slug: t.slug,
            description: t.description,
            quantity: Number(formatNumber(t.quantity)),
            unit: t.unit,
            unitPrice: t.unit_price,
          };
        });
        return {
          id: item.id,
          productId: item.product_id,
          slug: item.slug,
          description: item.description,
          quantity: item.quantity,
          unit: item.unit,
          unitPrice: item.unit_price,
          similarSuggestions: suggestions,
        };
      }),
      translationJobs: order_detail_without_auth?.translation_jobs?.map((elem: OrderTranslationJobsAltCase) => {
        let lineItems: OrderLineItem[] = [];
        let deliveryDate = "";
        try {
          const result = JSON.parse(elem.result);
          lineItems =
            result.line_items?.map((item: OrderLineItemAltCase) => {
              let similarSuggestions: OrderLineItemAltCase[] = [];
              if (typeof item.similar_suggestions === "string") {
                try {
                  similarSuggestions = JSON.parse(item.similar_suggestions);
                } catch (err) {
                  console.log(err);
                }
              } else {
                similarSuggestions = item.similar_suggestions ?? [];
              }
              const suggestions = similarSuggestions?.map((t) => ({
                productId: t.product_id,
                slug: t.slug,
                description: t.description,
                quantity: t.quantity,
                unit: t.unit,
                unitPrice: t.unit_price,
              }));
              return {
                productId: item.product_id,
                slug: item.slug,
                description: item.description,
                quantity: item.quantity,
                similarSuggestions: suggestions,
                score: item.score,
                textRange: item.text_range,
                isManual: item.is_manual ?? false,
              };
            }) ?? [];
          deliveryDate = result.delivery_date;
        } catch (err) {
          console.log(err);
        }
        return {
          id: elem.id,
          action: elem.action,
          text: elem.text,
          result: {
            lineItems,
            deliveryDate,
          },
        };
      }),
      createdTs: order_detail_without_auth.created_ts,
      updatedTs: order_detail_without_auth.updated_ts,
      deliveryDate: order_detail_without_auth.delivery_date,
    };
    const token = window.atob(order_detail_without_auth.salt);
    // @ts-expect-error
    return { order, token, filterIds: Array.from(filterIds) };
  } catch (error) {
    return {};
  }
}

export default function OrderView() {
  const { order } = (useLoaderData() as { order: OrderDetailWithoutAuth }) ?? {};
  const { state } = useNavigation();
  const navigate = useNavigate();

  const handleNewOrder = () => {
    try {
      const { user } = getUserCompany();
      navigate("/orders?m=order");
    } catch (error) {
      setCookie(redirectUrlKEY, "/orders?m=order");
      navigate(`/login?redirect=/orders/new`);
    }
  };

  return (
    <Box
      flex="1"
      h="100%"
      bgColor="#f1f1f1"
      overflow="auto"
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      zIndex={10}
    >
      <Box
        textAlign="center"
        fontWeight="500"
        fontSize="16px"
        mb="2"
        pos="sticky"
        top="0"
        borderBottom="1px"
        borderColor="#353535"
        bgColor="#19181b"
        color="#ffffff"
        h="48px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        zIndex={100}
      >
        GrubMarket AI Order
        <Button pos="absolute" size="sm" colorScheme="primary" right="4" onClick={handleNewOrder}>
          New Order
        </Button>
      </Box>
      {state == "loading" && (
        <Box
          position="absolute"
          top="0"
          right="0"
          bottom="0"
          left="0"
          backgroundColor="rgba(255, 255, 255, 0.7)"
          zIndex="9999"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="primary.500" size="xl" />
        </Box>
      )}
      {order ? (
        <OrderDetail />
      ) : (
        <Box textAlign="center" mt="10">
          Order Not Found
        </Box>
      )}
    </Box>
  );
}

function OrderDetail() {
  const { order, token, filterIds } =
    (useLoaderData() as { order: OrderDetailWithoutAuth; token: string; filterIds: string[] }) ?? {};
  const [lineItems, setLineItems] = useState<OrderLineItem[]>([]);
  const [changedItems, setChangedItems] = useState<OrderLineItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const revalidator = useRevalidator();
  const {
    isOpen: isTipVisible,
    onClose: onTipClose,
    onOpen: onTipOpen,
  } = useDisclosure({ defaultIsOpen: getCookie(tipPromptKEY) !== "true" });
  const [curIndex, setCurIndex] = useState<number | null>(null);
  const { isOpen, onToggle, onOpen, onClose } = useDisclosure();
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const cancelAlertRef = useRef<HTMLButtonElement>(null);
  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
  const cancelConfirmRef = useRef<HTMLButtonElement>(null);
  const [deleteId, setDeleteId] = useState<string | null>();
  const [totalAmount, setTotalAmount] = useState(0);
  const [total, setTotal] = useState(0);
  const [deliveryDate, setDeliveryDate] = useState<string | null>(order.deliveryDate ?? getTomorrowDate());
  const toast = useToast();
  const isNeedsReview = order.status === "NeedsReview";

  const isDateChange = useMemo(() => {
    return deliveryDate != order.deliveryDate;
  }, [order, deliveryDate]);

  useEffect(() => {
    async function genLineItems() {
      const { company }: { company: CompanyAltCase } = await graphQLClientWithAuth(token).request(
        SEARCH_PRICE_SHEET_BY_CUSTOMER_ID_QUERY,
        {
          companyId: order.supplierPid,
          customerId: order.customerId,
          search: {
            filter_ids: filterIds.join(","),
          },
          offset: 0,
          maxLength: 100,
          invalidateCache: false,
        }
      );
      const { price_sheet } = company.customer;
      const priceSheetMap = new Map<string, PriceSheetItem>();
      price_sheet.forEach((elem) => {
        const image = elem.images?.[0]?.url ?? "";
        priceSheetMap.set(elem.id, {
          id: elem.id,
          name: elem.name,
          slug: elem.slug,
          unit: elem.unit,
          unitPrice: elem.unit_price == null ? null : parseFloat(elem.unit_price ?? "0"),
          productPrice: elem.product_price == null ? null : Number(formatNumber(elem.product_price)),
          packageSize: elem.package_size == null ? null : parseFloat(elem.package_size),
          unitText:
            elem.package_size == null || Number(elem.package_size) == 1 || elem.package_unit == null
              ? elem.unit ?? ""
              : `${elem.package_unit} (${elem.package_size} ${elem.unit})`,
          image,
        });
      });
      const list: OrderLineItem[] = [];
      order?.lineItems?.map((elem) => {
        const similarSuggestions = (elem.similarSuggestions ?? []).map((item) => {
          const product = priceSheetMap.get(item.productId);
          return {
            ...item,
            unit: product?.unit,
            unitPrice: product?.unitPrice,
            productPrice: product?.productPrice,
            packageSize: product?.packageSize,
            unitText: product?.unitText,
            image: product?.image,
          };
        });
        const product = priceSheetMap.get(elem.productId);
        list.push({
          id: elem.id,
          description: elem.description,
          productId: elem.productId,
          slug: elem.slug,
          quantity: elem.quantity,
          unit: product?.unit,
          unitPrice: product?.unitPrice,
          productPrice: product?.productPrice,
          packageSize: product?.packageSize,
          unitText: product?.unitText,
          image: product?.image,
          selected: 0,
          isManual: false,
          similarSuggestions: [
            {
              productId: elem.productId,
              description: elem.description,
              quantity: elem.quantity,
              unit: product?.unit,
              unitPrice: product?.unitPrice,
              productPrice: product?.productPrice,
              packageSize: product?.packageSize,
              unitText: product?.unitText,
              image: product?.image,
            },
            ...similarSuggestions,
          ],
        });
      });
      setLineItems(list);
    }
    genLineItems();
    setDeliveryDate(order.deliveryDate ?? getTomorrowDate());
  }, [order]);

  useEffect(() => {
    const changedItems = lineItems.filter((item) => {
      if (item.selected !== 0 || item.quantity === 0) return true;
      if (item.isManual && item.productId != item.manualRaw?.productId) return true;
      return false;
    });
    setChangedItems(changedItems);
    let _totalAmount: number | null = 0;
    let _total: number = 0;
    lineItems.forEach((elem) => {
      const quantity = elem.isManual && elem.manualRaw?.productId ? elem.manualRaw.quantity : elem.quantity;
      if (elem.productPrice == null) {
        _totalAmount = null;
      } else {
        if (_totalAmount != null) {
          const price = Number(elem.productPrice ?? 0);
          const cost = Number(formatNumber(price * quantity));
          _totalAmount += cost;
        }
      }
      if (quantity) {
        _total += 1;
      }
    });
    setTotalAmount(_totalAmount);
    setTotal(_total);
  }, [lineItems]);

  const handleDelete = () => {
    setLineItems((prevState) =>
      prevState.map((t, idx) => {
        if (t.productId === deleteId) {
          return {
            ...t,
            quantity: 0,
            similarSuggestions: t.similarSuggestions?.map((elem) => ({ ...elem, quantity: 0 })),
          };
        }
        return t;
      })
    );
    onAlertClose();
  };

  const getItems = () => {
    // @ts-expect-error
    const items = [];
    lineItems.forEach((elem) => {
      const product = elem.isManual ? elem.manualRaw! : elem.similarSuggestions![elem.selected!];
      const similarSuggestions =
        elem.similarSuggestions
          ?.filter((t) => t.productId != product.productId)
          .map((t) => ({
            product_id: t.productId,
            slug: t.slug,
            description: t.description,
            quantity: t.quantity,
            unit: t.unit,
            unit_price: t.unitPrice,
            product_price: t.productPrice ?? 0,
          })) ?? [];
      if (product.quantity) {
        items.push({
          product_id: product.productId,
          slug: product.slug,
          description: product.description,
          quantity: product.quantity,
          unit: product.unit,
          unit_price: product.unitPrice,
          product_price: product.productPrice ?? 0,
          is_manual: product.isManual,
          similar_suggestions: JSON.stringify(similarSuggestions),
        });
      }
    });
    // @ts-expect-error
    return items;
  };

  const getEditedJobs = () => {
    // @ts-expect-error
    const editedJobs = [];

    const createdOrderProductIds: string[] = [];
    order.translationJobs?.forEach((job) => {
      const result: {
        product_id: string;
        slug: string | undefined;
        description: string;
        quantity: number;
        unit: string | undefined;
        unit_price: number | null | undefined;
        product_price: number | null | undefined;
        score: number | undefined;
        similar_suggestions: string;
        text_range: number[] | undefined;
        is_manual?: boolean;
      }[] = [];
      job?.result?.lineItems?.forEach((item) => {
        const changedItem = changedItems.find((elem) => elem.productId === item.productId);
        const product = changedItem
          ? changedItem.isManual
            ? changedItem.manualRaw!
            : changedItem.similarSuggestions![changedItem.selected ?? 0]
          : item;
        if (job.action === "CreateOrder") {
          createdOrderProductIds.push(product.productId);
        }
        const similarSuggestions =
          (changedItem ? changedItem : item).similarSuggestions
            ?.filter((elem) => elem.productId != product.productId)
            .map((elem) => ({
              product_id: elem.productId,
              slug: elem.slug,
              quantity: product.quantity,
              description: elem.description,
              unit: elem.unit,
              unit_price: elem.unitPrice,
              product_price: elem.productPrice,
            })) ?? [];
        let quantity = changedItem?.quantity === 0 ? 0 : item.quantity;
        if (changedItem?.isManual && changedItem.manualRaw?.productId && changedItem.manualRaw?.quantity) {
          if (changedItem.manualRaw?.quantity != quantity) {
            quantity = [
              createdOrderProductIds.includes(changedItem.manualRaw.productId) ? "CreateOrder" : "AddItem",
              "UpdateQty",
            ].includes(job.action)
              ? changedItem.manualRaw?.quantity
              : 0;
          } else {
            quantity = changedItem.manualRaw?.quantity;
          }
        }
        result.push({
          product_id: product.productId,
          slug: product.slug,
          description: product.description,
          quantity,
          unit: changedItem?.isManual ? product.unit : item.unit,
          unit_price: changedItem?.isManual ? product.productPrice : item.unitPrice,
          product_price: changedItem?.isManual ? product.productPrice : item.productPrice,
          score: item.score,
          similar_suggestions: JSON.stringify(similarSuggestions),
          text_range: item.textRange,
          is_manual: changedItem?.isManual,
        });
      });
      editedJobs.push({
        id: job.id,
        action: job.action,
        result: JSON.stringify({ line_items: result }),
        text: job.text,
      });
    });
    // @ts-expect-error
    return editedJobs;
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await graphQLClientWithoutAuth.request(UPDATE_DRAFT_ORDER, {
        supplierPid: order.supplierPid,
        orderId: order.id,
        lineItems: getItems().filter((elem) => elem.quantity > 0),
        editedJobs: getEditedJobs(),
        deliveryDate,
        buyerNote: "",
      });
      revalidator.revalidate();
      toast({
        title: "Saved successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    try {
      onConfirmClose();
      setConfirmLoading(true);
      await graphQLClientWithAuth(token).request(CONFIRM_ORDER, {
        supplierPid: order.supplierPid,
        orderId: order.id,
        lineItems: getItems().filter((elem) => elem.quantity > 0),
        editedJobs: getEditedJobs(),
        deliveryDate,
        buyerNote: "",
      });
      revalidator.revalidate();
      toast({
        title: "Confirmed successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <Box px="4">
      <Flex fontSize="12px" mb="2" flexDir={"column"}>
        <Flex fontWeight="600" alignItems="center" justify="space-between">
          <Text fontSize="16" fontWeight="600" textTransform="capitalize">
            {order?.customerName?.toLowerCase()}
          </Text>
          <Flex alignItems="center">
            <Text mr="2px">Order Status:</Text>
            <Text
              as="span"
              color={
                {
                  confirmed: "blue.400",
                  needsreview: "primary.400",
                  initialized: "gray.400",
                }[order.status.toLowerCase()]
              }
            >
              {order.status}
            </Text>
          </Flex>
        </Flex>
        <Flex align="center">
          <Text mr="2" fontWeight="600">
            #{order?.id}
          </Text>
          <Text mr="2" fontWeight="600">
            Delivery Date:
          </Text>
          {isNeedsReview ? (
            <Input
              placeholder="Select Delivery Date"
              size="xs"
              type="date"
              w="240"
              value={deliveryDate ?? ""}
              onChange={(e) => {
                setDeliveryDate(e.target.value);
              }}
            />
          ) : (
            <Text fontWeight="600">{formatDate(order.deliveryDate)}</Text>
          )}
        </Flex>
      </Flex>
      <Flex mb="4" justify="space-between">
        <Text>Your items({total} items)</Text>
        {totalAmount != null && <Text>Total: {formatAmount(totalAmount)}</Text>}
      </Flex>
      <Popover placement="bottom" onOpen={onOpen} onClose={onClose} isOpen={isOpen}>
        {lineItems.map((item, index) => {
          const product =
            (item.isManual && item.manualRaw?.productId && item.manualRaw) || item.similarSuggestions![item.selected!];
          const quantity = product.quantity ?? item.quantity;
          if (quantity === 0) return;
          let unitPriceText = "";
          if (product.productPrice == null && product.unitPrice == null) {
            unitPriceText = "MP";
          } else {
            if (product.productPrice != null) {
              unitPriceText += `$${product.unitPrice}`;
            }
            if (product.packageSize == null || Number(product.packageSize) != 1) {
              if (product.productPrice != null) {
                unitPriceText += "(";
              }
              unitPriceText += `$${product.unitPrice}/${product.unitText}`;
              if (product.productPrice != null) {
                unitPriceText += ")";
              }
            }
          }
          return (
            <Fragment key={index}>
              <PopoverTrigger>
                <Flex pos="relative">
                  <AspectRatio ratio={1 / 1} w="16" h="16" mr="2">
                    <Image src={product.image ?? DefaultProductImage} fallback={<Skeleton speed={0} />} />
                  </AspectRatio>
                  <Box flex="1">
                    <Text
                      as="span"
                      fontWeight="semibold"
                      borderRadius="4"
                      px="1"
                      bgColor="#ddd"
                      onClick={() => {
                        if (isNeedsReview) {
                          setCurIndex(index);
                          onOpen();
                        }
                      }}
                    >
                      {product.description}
                      {isNeedsReview && (
                        <Icon
                          as={HiChevronDown}
                          transform="auto"
                          rotate={index == curIndex && isOpen ? "180deg" : ""}
                          boxSize="3"
                          transition="all .25s ease"
                        />
                      )}
                    </Text>
                    <Text>
                      Unit:{" "}
                      <Text as="span" textTransform="lowercase">
                        {product.unitText}
                      </Text>
                    </Text>

                    <Flex justify="space-between">
                      <Box>
                        {product.productPrice == null && product.unitPrice == null ? (
                          <Text color="#57bc5a" fontWeight="bold">
                            MP
                          </Text>
                        ) : (
                          <Flex alignItems="center" fontSize="14">
                            {product.productPrice != null && (
                              <Text as="span" color="#57bc5a" fontWeight="bold" mr="3">
                                ${formatNumber(product.productPrice)}
                              </Text>
                            )}
                            {product.packageSize == null ||
                              (Number(product.packageSize) != 1 && (
                                <Box fontSize="12" fontWeight="semibold">
                                  {product.productPrice != null ? <Text as="span"> (</Text> : ""}
                                  <Text as="span" color="#ffca00">
                                    ${formatNumber(product.productPrice)}
                                  </Text>
                                  <Text as="span" fontSize="10">
                                    / {product.unitText}
                                  </Text>
                                  {product.productPrice != null ? <Text as="span"> )</Text> : ""}
                                </Box>
                              ))}
                          </Flex>
                        )}
                      </Box>

                      <Flex align="center">
                        <Text fontSize="18" fontWeight="semibold">
                          x {quantity}
                        </Text>
                        {isNeedsReview && (
                          <Icon
                            ml="4"
                            as={MdDelete}
                            boxSize={5}
                            onClick={() => {
                              setDeleteId(item.productId);
                              onAlertOpen();
                            }}
                          />
                        )}
                      </Flex>
                    </Flex>
                  </Box>
                  {curIndex === index && (
                    <PopoverAnchor>
                      <Box pos="absolute" bottom="0" left="0" w="full" h="full" zIndex="-1"></Box>
                    </PopoverAnchor>
                  )}
                </Flex>
              </PopoverTrigger>
              {index === 0 && isTipVisible && isNeedsReview && (
                <Alert status="info">
                  <AlertIcon />
                  <AlertDescription flex="1">Tap an item to see change to a similar option</AlertDescription>
                  <CloseButton
                    alignSelf="flex-start"
                    position="relative"
                    right={-1}
                    top={-1}
                    onClick={() => {
                      setCookie(tipPromptKEY, "true");
                      onTipClose();
                    }}
                  />
                </Alert>
              )}
              <Divider my="2" />
            </Fragment>
          );
        })}

        {curIndex != null && curIndex > -1 && (
          <LineItemEditor
            isOpen={isOpen}
            lineItems={lineItems}
            token={token}
            order={order}
            curIndex={curIndex}
            setLineItems={setLineItems}
            onClose={onClose}
          />
        )}
      </Popover>

      <Flex pos="sticky" bottom="0" w="full" px="4" py="2" gap={2}>
        {(changedItems.length > 0 || isDateChange) && (
          <Button
            w="full"
            color="white"
            bgColor="#000000"
            _hover={{ bgColor: "#000000" }}
            size="lg"
            fontSize="large"
            isLoading={loading}
            onClick={handleSubmit}
          >
            Save
          </Button>
        )}
        {isNeedsReview && (
          <Button
            w="full"
            color="white"
            bgColor="#000000"
            _hover={{ bgColor: "#000000" }}
            size="lg"
            fontSize="large"
            isLoading={confirmLoading}
            onClick={onConfirmOpen}
          >
            Confirm
          </Button>
        )}
      </Flex>

      <AlertDialog isCentered isOpen={isAlertOpen} leastDestructiveRef={cancelAlertRef} onClose={onAlertClose}>
        <AlertDialogOverlay>
          <AlertDialogContent w="90%">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete
            </AlertDialogHeader>
            <AlertDialogBody>Are you sure to delete this item? </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelAlertRef} onClick={onAlertClose}>
                Cancel
              </Button>
              <Button colorScheme="red" ml={3} onClick={handleDelete}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog isCentered isOpen={isConfirmOpen} leastDestructiveRef={cancelConfirmRef} onClose={onConfirmClose}>
        <AlertDialogOverlay>
          <AlertDialogContent w="90%">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Order
            </AlertDialogHeader>
            <AlertDialogBody>
              After confirmation, no further changes can be made. Any additional items will be added to a new order.{" "}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelAlertRef} onClick={onConfirmClose}>
                Cancel
              </Button>
              <Button colorScheme="primary" ml={3} onClick={handleConfirm}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

function LineItemEditor(props: {
  isOpen: boolean;
  lineItems: OrderLineItem[];
  curIndex: number;
  order: OrderDetailWithoutAuth;
  token: string;
  setLineItems: React.Dispatch<React.SetStateAction<OrderLineItem[]>>;
  onClose: () => void;
}) {
  const { isOpen, lineItems, curIndex, order, token, setLineItems, onClose } = props;
  const lineItem = lineItems[curIndex];
  const inputRef = useRef<HTMLInputElement>(null);

  const handleProductChange = (product: ProductOption) => {
    setLineItems((prevState) =>
      prevState.map((t, idx) => {
        if (idx === curIndex) {
          return {
            ...t,
            isManual: true,
            manualRaw: {
              label: product.name,
              value: product.id,
              quantity: t.manualRaw?.quantity ?? t.quantity,
              description: product.name,
              productId: product.id,
              image: product.image,
              unit: product?.unit,
              unitPrice: product?.unitPrice,
              productPrice: product?.productPrice,
              packageSize: product?.packageSize,
              unitText: product?.unitText,
            },
          };
        }
        return t;
      })
    );
  };
  const handleQtyChange = (strValue: string, numberValue: number) => {
    setLineItems((prevState) =>
      prevState.map((t, idx) => {
        if (idx === curIndex) {
          const manualRaw = { productId: "", description: "", ...t.manualRaw, quantity: numberValue };
          return {
            ...t,
            manualRaw,
          };
        }
        return t;
      })
    );
  };
  return (
    <>
      {isOpen && (
        <Box
          pos="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bgColor="rgba(0, 0, 0, 0.7)"
          zIndex={10}
          onClick={() => onClose()}
        />
      )}
      <PopoverContent
        _focusVisible={{ outline: "none" }}
        w="92vw"
        mb="3"
        bgColor="#f1f1f1"
        borderWidth="1px"
        borderColor="#666"
      >
        <PopoverBody>
          <Text fontWeight={600} color="gray.600" mb="3">
            {lineItem.isManual ? "Manually Enter Item" : "Suggested Items"}
          </Text>
          {lineItem.isManual ? (
            <Flex mb={10}>
              <Box>
                <Text fontWeight={600} color="gray.600" mb="2">
                  Quantity
                </Text>
                <NumberInput maxW={24} value={lineItem.manualRaw?.quantity || ""} onChange={handleQtyChange}>
                  <NumberInputField autoFocus ref={inputRef} />
                  <NumberInputStepper>
                    <NumberIncrementStepper children={<HiChevronUp />} />
                    <NumberDecrementStepper children={<HiChevronDown />} />
                  </NumberInputStepper>
                </NumberInput>
              </Box>
              <Box fontSize="16px" mx="2" pt="34px">
                <Icon as={MdClear}></Icon>
              </Box>
              <Box flex="1">
                <Text fontWeight={600} color="gray.600" mb="2">
                  Product
                </Text>
                <ProductSelect
                  companyId={order.supplierPid}
                  customerId={order.customerId}
                  lineItem={lineItem}
                  token={token}
                  handleChange={handleProductChange}
                />
              </Box>
            </Flex>
          ) : (
            <Stack minH={50}>
              {lineItem.similarSuggestions?.map((item, index) => (
                <Checkbox
                  key={index}
                  py="2"
                  px="3"
                  _hover={{ bgColor: "gray.200" }}
                  bgColor={lineItem.selected === index ? "gray.200" : ""}
                  isChecked={lineItem.selected === index}
                  onChange={() => {
                    if (lineItem.selected != index) {
                      setLineItems((prevState) =>
                        prevState.map((t, idx) => {
                          if (idx === curIndex) {
                            const product = t.similarSuggestions![idx];
                            return {
                              ...t,
                              selected: index,
                              isManual: false,
                            };
                          }
                          return t;
                        })
                      );
                    }
                  }}
                >
                  {item.quantity} {item.description}
                </Checkbox>
              ))}
            </Stack>
          )}
          <Flex gap="2" mt="2">
            {lineItem.isManual ? (
              <Button
                flex="1"
                color="white"
                bgColor="#000000"
                _hover={{ bgColor: "#000000" }}
                size="lg"
                fontSize="large"
                px="6"
                onClick={() => {
                  setLineItems((prevState) =>
                    prevState.map((t, idx) => {
                      if (idx === curIndex) {
                        return {
                          ...t,
                          isManual: !t.isManual,
                        };
                      }
                      return t;
                    })
                  );
                }}
              >
                AI Suggestions
              </Button>
            ) : (
              <Button
                flex="1"
                color="white"
                bgColor="#000000"
                _hover={{ bgColor: "#000000" }}
                size="lg"
                fontSize="large"
                px="6"
                onClick={() => {
                  setLineItems((prevState) =>
                    prevState.map((t, idx) => {
                      if (idx === curIndex) {
                        return {
                          ...t,
                          isManual: !t.isManual,
                        };
                      }
                      return t;
                    })
                  );
                }}
              >
                Manual
              </Button>
            )}
            <Button
              flex="1"
              color="white"
              bgColor="#000000"
              size="lg"
              fontSize="large"
              px="6"
              onClick={() => {
                onClose();
              }}
            >
              OK
            </Button>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </>
  );
}

async function fetchProducts(
  searchText: string,
  token: string,
  companyId: string,
  customerId: string,
  offset: number = 0,
  maxLength: number = 20
): Promise<{ products: PriceSheetItem[] }> {
  const { company }: { company: CompanyAltCase } = await graphQLClientWithAuth(token).request(
    SEARCH_PRICE_SHEET_BY_CUSTOMER_ID_QUERY,
    {
      companyId,
      customerId,
      search: {
        text: searchText,
      },
      offset: 0,
      maxLength: 20,
      invalidateCache: false,
    }
  );
  const { price_sheet } = company.customer;
  const products =
    price_sheet?.map((elem) => {
      const image = elem.images?.[0]?.url ?? "";
      return {
        id: elem.id,
        name: elem.name,
        slug: elem.slug,
        unit: elem.unit,
        unitPrice: elem.unit_price == null ? null : parseFloat(elem.unit_price ?? "0"),
        productPrice: elem.product_price == null ? null : Number(formatNumber(elem.product_price)),
        packageSize: elem.package_size == null ? null : parseFloat(elem.package_size),
        unitText:
          elem.package_size == null || Number(elem.package_size) == 1 || elem.package_unit == null
            ? elem.unit ?? ""
            : `${elem.package_unit} (${elem.package_size} ${elem.unit})`,
        image,
      };
    }) ?? [];
  return { products: products ?? [] };
}

function ProductSelect(props: {
  token: string;
  lineItem: OrderLineItem;
  companyId: string;
  customerId: string;
  handleChange: (data: ProductOption) => void;
}) {
  const { token, lineItem, companyId, customerId, handleChange } = props;
  const [isChanged, setIsChanged] = useState(false);

  const loadSuggestions = _.debounce((inputValue, callback) => {
    fetchProducts(inputValue, token, companyId, customerId).then(({ products }) => {
      const list = products?.map((product) => ({
        ...product,
        label: product.name,
        value: product.id,
      }));
      callback(list);
    });
  }, 300);
  const value = lineItem.manualRaw && {
    label: `[${lineItem.manualRaw.productId}] ${lineItem.manualRaw.description}`,
    value: lineItem.manualRaw.productId,
  };
  return (
    <Box>
      <AsyncSelect
        value={value}
        selectedOptionStyle="check"
        loadOptions={loadSuggestions}
        blurInputOnSelect={false}
        onChange={(value) => {
          setIsChanged(true);
          handleChange(value as ProductOption);
        }}
      />
      {/* {isChanged && (
        <Text align={"right"} fontWeight="semibold" color={"primary.400"}>
          Product changed
        </Text>
      )} */}
    </Box>
  );
}
