import { searchPriceSheetByCustomerId } from "@/api/queries/price-sheet";
import queryClient from "@/api/query-client";
import { Chunk, ProductOption, OrderDetail, PriceSheetItem } from "@/utils/types";
import { Box } from "@chakra-ui/react";
import { AsyncSelect } from "chakra-react-select";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useLoaderData } from "react-router-dom";

export default function ProductSelect({
  chunk,
  handleChange,
  isDisabled,
  isFocused,
  searchInput,
  setSelectIndex,
  setIsSearchInputFocused,
  setIsSearchDropdownOpen,
}: {
  chunk: Chunk;
  handleChange: (data: ProductOption) => void;
  isDisabled: boolean;
  isFocused: boolean;
  searchInput: any;
  setSelectIndex: any;
  setIsSearchInputFocused: any;
  setIsSearchDropdownOpen: any;
}) {
  const { order } = (useLoaderData() as { order: OrderDetail }) ?? {};
  const [inputValue, setInputValue] = useState("");
  const [isChanged, setIsChanged] = useState(false);

  const loadSuggestions = _.debounce((inputValue, callback) => {
    setInputValue(inputValue);
    fetchProducts(inputValue, order).then(({ products }) => {
      const list = products?.map((elem) => ({
        ...elem,
        label: `${elem.slug ?? elem.id} - ${elem.name}`,
        value: elem.id,
      }));
      callback(list);
      if (list.length) {
        setIsSearchDropdownOpen(true);
      } else {
        setIsSearchDropdownOpen(false);
      }
    });
  }, 300);

  useEffect(() => {
    setIsSearchDropdownOpen(false);
  }, []);

  useHotkeys(
    "esc",
    () => {
      setIsSearchInputFocused(false);
      setIsSearchDropdownOpen(false);
    },
    { enableOnFormTags: ["input"], preventDefault: true }
  );
  useHotkeys(
    "enter",
    () => {
      setIsSearchInputFocused(false);
      setIsSearchDropdownOpen(false);
    },
    { enableOnFormTags: ["input"], preventDefault: true }
  );

  return (
    <Box w="full">
      <AsyncSelect
        value={chunk.manual?.raw ?? ""}
        selectedOptionStyle="check"
        loadOptions={loadSuggestions}
        onChange={(value) => {
          setIsChanged(true);
          handleChange(value as ProductOption);
        }}
        isDisabled={isDisabled}
        maxMenuHeight={180}
        autoFocus={isFocused}
        onFocus={() => {
          setSelectIndex(chunk.options?.length);
          setIsSearchInputFocused(true);
        }}
        onBlur={() => {
          setIsSearchInputFocused(false);
        }}
        size="sm"
        ref={searchInput}
      />
      {/* {isChanged && (
        <Text align="left" fontWeight="medium" color="primary.400">
          This product for the order item has been updated
        </Text>
      )} */}
      {/* &nbsp;<Kbd fontSize="2xs">shift</Kbd>&nbsp;+&nbsp;<Kbd fontSize="2xs">→</Kbd> */}
    </Box>
  );
}
async function fetchProducts(
  searchText: string,
  order: OrderDetail,
  offset: number = 0,
  maxLength: number = 20
): Promise<{ products: PriceSheetItem[] }> {
  const fetchSuggestions = searchPriceSheetByCustomerId(
    order.companyId,
    order.customerId,
    searchText,
    "",
    offset,
    maxLength,
    false
  );
  const { products }: { products: PriceSheetItem[] } = (await queryClient.fetchQuery(fetchSuggestions)) ?? {};
  return { products: products ?? [] };
}
